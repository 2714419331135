body {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hidescroll::-webkit-scrollbar {
    display: none;
}

.hidescroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

a {
    color: #000;
    font-weight: bold;
    text-decoration: none;
}

a:visited {
    color: inherit;
}
